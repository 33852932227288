import React, { useEffect } from "react";
import { Header } from "../components/ImpactCall/Header";
import { Hero } from "../components/ImpactCall/Hero";
import { Promotore } from "../components/ImpactCall/Promotore";
import { CallFor } from "../components/ImpactCall/CallFor";
import { Prices } from "../components/ImpactCall/Prices";
import { Regolamento } from "../components/ImpactCall/Regolamento";
import { Partners } from "../components/ImpactCall/Partners";
import { Footer } from "../components/ImpactCall/Footer";
import { StyledImpactCall } from "../components/ImpactCall/style";

const setFontSize = () => {
  const page = document.querySelector("html");

  if (window.innerWidth >= 1600 && window.innerWidth < 1700) {
    page.style.fontSize = "110%";
  } else if (window.innerWidth >= 1700 && window.innerWidth < 1800) {
    page.style.fontSize = "120%";
  } else if (window.innerWidth >= 1800 && window.innerWidth < 1921) {
    page.style.fontSize = "130%";
  } else if (window.innerWidth >= 1921) {
    page.style.fontSize = "140%";
  } else {
    page.style.fontSize = "100%";
  }
};

const ImpactCall = () => {
  useEffect(() => {
    setFontSize();
    window.addEventListener("resize", () => {
      setFontSize();
    });
  }, []);

  return (
    <StyledImpactCall>
      <Header />
      <Hero />
      <Promotore />
      <CallFor />
      <Regolamento />
      <Prices />
      <Partners />
      <Footer />
    </StyledImpactCall>
  );
};

export default ImpactCall;
